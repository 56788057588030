export default [
  'Courier',
  'Courier-Bold',
  'Courier-Oblique',
  'Helvetica',
  'Helvetica-Bold',
  'Helvetica-Oblique',
  'Times-Roman',
  'Times-Bold',
  'Times-Italic',
];
